import './App.scss';

const data = [
  {
    header: 'Flight Events',
    content: 'A map to track your friends\' and your aircraft for group flights and community events',
    actions: [
      {
        text: 'Open Site',
        url: 'https://events.flighttracker.tech',
      },
      {
        text: 'GitHub',
        url: 'https://github.com/nguyenquyhy/Flight-Events',
      },
    ],
  },
  {
    header: 'Flight Tracker Stream Deck plugins',
    content: 'A Stream Deck plugin to interact with Microsoft Flight Simulation',
    actions: [
      {
        text: 'Flightsim.to',
        url: 'https://flightsim.to/file/3178/flight-tracker-streamdeck-plugin',
      },
      {
        text: 'GitHub',
        url: 'https://github.com/nguyenquyhy/Flight-Tracker-StreamDeck',
      },
    ],
  },
  {
    header: 'Flight Recorder',
    content: 'Record and replay flights in Microsoft Flight Simulator',
    actions: [
      {
        text: 'Flightsim.to',
        url: 'https://flightsim.to/file/8163/flight-recorder',
      },
      {
        text: 'GitHub',
        url: 'https://github.com/nguyenquyhy/Flight-Recorder',
      },
    ],
  }
]

const App = () => (
  <section className="section">
    <div className="container">
      <h1 className="title">
        Flight Tracker
      </h1>
      <p className="subtitle">
        Please enjoy some free tools that I have built for Microsoft Flight Simulator!
      </p>

      <div className="columns">
        {data.map(item => (
          <div className="column">  
            <div key={item.header} className="card">
              <header className="card-header">
                <p className="card-header-title">
                  {item.header}
                </p>
              </header>
              <div className="card-content">
                {item.content}
              </div>
              {item.actions && (
                <footer className="card-footer">
                  {item.actions.map(action => (
                    <a key={action.text} href={action.url} target="blank" className="card-footer-item">{action.text}</a>
                  ))}
                </footer>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>      
  </section>
);

export default App;
